@import '../../app/globalStyles/_color-variables';
@import '../../app/globalStyles/_typography-variables';
@import '../../app/globalStyles/_mixins';

.bulkform-container {
  width: 100%;

  span {
    display: block;
    width: 100%;
    padding-bottom: 5px;
  }

  .bulkform-input-container {
    display: flex;
    flex-wrap: wrap;
    grid-column-gap: 1rem;
    margin-block-end: 10px;
    width: 40%;
  }

  .bulkform-input {
    @include box-shadow;

    background-color: $white;
    border: none;
    flex: 1 1 auto;
    font-size: $relative-font-size-em;
    font-weight: $base-font-weight-bold;
    height: 34px;
    margin-block-end: 10px;
    overflow: hidden;
    padding: 5px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  @media only screen and (max-width: 765px) {
    display: flex;
    flex-wrap: wrap;

    button {
      flex: 1 1 90%;
      margin-block-start: 10px;

      &:first-of-type {
        margin-inline-start: 0;
      }

      &:only-of-type {
        flex: 1 1 100%;
      }
    }

    .button-wrapper > div {
      flex: 1 1 90%;
    }

    .file-input-wrapper {
      padding: 10px 20px;
    }
  }
}
