@import '../../app/globalStyles/_color-variables';
@import '../../app/globalStyles/_typography-variables';
@import '../../app/globalStyles/_mixins';

.file-input {
  width: 100%;

  // we hide this input field and instead style a div with a click handler
  input[type='file'] {
    @include accessible-display-none;
  }

  // this element is styled to *look* like the input field
  div[role='button'] {
    @include box-shadow;

    background-color: $white;
    border: none;
    cursor: pointer;
    display: flex;
    flex: 1 1 45%;
    font-size: $relative-font-size-em;
    font-weight: $base-font-weight-bold;
    height: 34px;
    min-width: 0;

    span {
      display: block;
      flex: 1 1 0;
      overflow: hidden;
      padding: 5px 10px;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100px;
    }
  }

  // "Select File", "Select Different File", and "Check And Preview" buttons
  button {
    flex: 0 0 160px;
    font-size: $relative-font-size-em;
    margin-inline-start: 10px;
    width: 100%;

    // "Select File" button
    &:only-of-type {
      flex: 0 0 100px;
      margin-inline-start: 10px;
    }
  }

  // "Select Different File" and "Check and Preview" buttons
  .button-wrapper {
    display: flex;
    flex: 1 1 90%;
    flex-wrap: wrap;

    & > div {
      display: flex;
    }
  }

  &.file-input-error {
    label span:not(:first-child) {
      color: #ED1C24;
  } 

    .button-wrapper > div:first-of-type {
      border: 2px solid $red;
      color: $red;
      line-height: 20px;
    }
  }

  &.file-input-success {
    label > span:last-of-type {
      color: $green;
    }

    .button-wrapper > div:first-of-type {
      border: 2px solid $green;
      color: $green;
      line-height: 20px;
    }
  }

  @media only screen and (max-width: 765px) {
    display: flex;
    flex-wrap: wrap;

    button {
      flex: 1 1 90%;
      margin-block-start: 10px;

      &:first-of-type {
        margin-inline-start: 0;
      }

      &:only-of-type {
        flex: 1 1 100%;
      }
    }

    .button-wrapper > div {
      flex: 1 1 90%;
    }

    .file-input-wrapper {
      padding: 10px 20px;
    }
  }
}
